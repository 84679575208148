import React, { useState } from 'react';
import { Link } from 'gatsby';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userSelector } from '../../redux/selectors';
import { signInLocal } from '../../redux/actions';
import { apiLocation } from '../../../../config/env.json';

import { User } from '../../redux/prop-types';
import GoogleIcon from '../../assets/icons/google-icon';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import Input from '../ui/Input';
import { Spacer } from '../helpers';
import InputPassword from '../ui/input-password';

type FormValues = {
  email: string;
  password: string;
};

type LoginLocalProps = {
  email?: string;
  password?: string;
  signInLocal: (formValues: FormValues) => void;
};

const defaultValues = {
  email: '',
  password: ''
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  signInLocal
};

function SignIn(props: LoginLocalProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] =
    useState<FormValues>(defaultValues);

  const { t } = useTranslation();

  function handleEmailChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, email: '' });
    return setFormValues({ ...formValues, email: value });
  }

  function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    setValidationForm({ ...validationForm, password: '' });
    return setFormValues({ ...formValues, password: value });
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const { signInLocal } = props;
    if (validateForm(formValues)) {
      signInLocal(formValues);
    }
  }

  function isEmptyFields(
    values: FormValues
  ): Partial<Record<keyof FormValues, string>> | undefined {
    const fieldTranslations: Record<keyof FormValues, string> = {
      email: 'E-mail',
      password: 'Senha'
    };
    const fieldsToCheck: (keyof FormValues)[] = ['email', 'password'];
    const validationMessages: Partial<Record<keyof FormValues, string>> = {};

    fieldsToCheck.forEach(field => {
      if (!values[field]) {
        validationMessages[
          field
        ] = `Campo '${fieldTranslations[field]}' está em branco, verfique suas informações e tente novamente.`;
      }
    });

    if (Object.keys(validationMessages).length > 0) {
      return validationMessages;
    }
  }

  function validateForm(values: FormValues) {
    let validationMessages: Partial<Record<keyof FormValues, string>> = {};

    const emptyFieldMessages = isEmptyFields(values);
    if (emptyFieldMessages) {
      validationMessages = { ...validationMessages, ...emptyFieldMessages };
    }

    if (Object.keys(validationMessages).length > 0) {
      setValidationForm({ ...validationForm, ...validationMessages });
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <section className='info-section'>
        <p id='main-title-sign-in'>{t('register.headings.sign-in-heading1')}</p>
        <Spacer size='medium' />
        <p id='second-title-sign-in'>
          {t('register.headings.sign-in-heading2')}
        </p>
      </section>

      <section className='register-section'>
        <a className='google-container' href={`${apiLocation}/signin-google`}>
          <GoogleIcon className='google-icon' />
          <p>{t('register.buttons.sign-in-google')}</p>
        </a>

        <Spacer size='medium' />
        <p id='google-or-local'>Ou</p>
        <Spacer size='medium' />
        <form onSubmit={handleSubmit}>
          <Input
            name='email'
            type='email'
            placeholder='Email'
            value={formValues.email}
            onChange={handleEmailChange}
            hasError={!!validationForm.email}
            messageError={validationForm.email}
          />
          <InputPassword
            name='password'
            placeholder='Senha'
            value={formValues.password}
            onChange={handlePasswordChange}
            hasError={!!validationForm.password}
            messageError={validationForm.password}
          />
          <div className='form-btn'>
            <Button
              type='submit'
              buttonType={ButtonTypes.Primary}
              style={{ height: '48px', width: '100%' }}
            >
              {t('register.buttons.log-into-account')}
            </Button>
          </div>
        </form>
        <Spacer size='medium' />
        <div id='forgot-password-container'>
          <Link id='forgot-password' to='/forgot-password'>
            <p>{t('register.buttons.forgot-password')}</p>
          </Link>
        </div>
        <Spacer size='medium' />
        <p id='info-terms'>{t('register.headings.sign-up-accept-terms')}</p>
      </section>
    </>
  );
}

SignIn.displayName = 'SignInPage';

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
