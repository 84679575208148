import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { createFlashMessage } from '../components/Flash/redux';
import { FlashMessages } from '../components/Flash/redux/flash-messages';
import {
  postSignInLocal,
  postSignUpLocal,
  postConfirmVerificationEmail,
  putUtmData,
  postForgotPassword,
  putRecoveryPassword,
  getAccessUserByEcommerceWithoutCode,
  putRegisterSubscription,
  getAccessUserByEcommerce,
  checkUserInactiveStatusByEmail,
  unlockUserAccount,
  unlockGoogleUserAccount
} from '../utils/ajax';
import { BrazilStates } from '../utils/enums/states';
import { removeNacionalStorageInfo } from '../pages';
import { apiLocation } from '../../../config/env.json';
import { hardGoTo, openModalGoToUnlockPage } from './actions';
import { isSignedInSelector } from './selectors';

function* submitSignUpLocal({ payload }) {
  try {
    const codeLocalStorage = localStorage.getItem('devStart:codigo');
    const sourceLocalStorage = localStorage.getItem('devStart:source');
    const resultEcommerce = yield verifyEcommerceResult(payload.cpf);

    const studentData = yield call(
      formattedEcommerceResult,
      resultEcommerce,
      codeLocalStorage,
      cleanSourceInfo(sourceLocalStorage),
      payload
    );

    const signUpResult = yield call(postSignUpLocal, {
      email: payload.email,
      password: payload.password,
      cpf: payload.cpf
    });

    if (signUpResult.data.success) {
      const dataComplementResult = yield call(
        putRegisterSubscription,
        studentData
      );
      if (dataComplementResult.data.success) {
        window.dataLayer.push({
          event: 'gtm.formSubmit',
          'gtm.elementClasses': 'form-cadastro'
        });

        localStorage.removeItem('devStart:codigo');

        if (localStorage.getItem('devStart:utm') !== null) {
          yield put(updateAccessUtm(localStorage.getItem('devStart:utm')));
        }

        removeNacionalStorageInfo();

        yield put(
          createFlashMessage({
            type: 'success',
            message: FlashMessages.RegisterUser
          })
        );
        yield delay(4000);
        yield put(hardGoTo('/learn'));

        // const resultSendVerificationEmail = yield call(
        //   postSendEmailVerification,
        //   payload
        // );
        // if (resultSendVerificationEmail.success) {
        //   createFlashMessage({
        //     type: 'success',
        //     message: resultSendVerificationEmail.message
        //   });
        // }
      } else {
        yield put(
          createFlashMessage({
            type: 'danger',
            message: dataComplementResult.data.message
          })
        );
      }
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message: signUpResult.data.message
        })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function cleanSourceInfo(source) {
  return source ? source.replace(/"/g, '') : undefined;
}

function getSourceInfo(source) {
  const sourceArray = source.split('_');
  return sourceArray;
}

function isValidSourceParam(source) {
  const sourceArray = getSourceInfo(source);

  if (sourceArray[0] !== 'nacional') {
    return false;
  }

  const abbreviation = sourceArray[1];
  const statesArray = Object.values(BrazilStates);

  if (!statesArray.includes(abbreviation.toUpperCase())) {
    return false;
  }

  return true;
}

function getAccess(nivelAcesso, source) {
  return isValidSourceParam(source)
    ? 'limited_nacional'
    : nivelAcesso === 'COMPLETO'
    ? 'complete'
    : 'limited';
}

function getSource(code, nivel, turma, source) {
  const validSource = source ? isValidSourceParam(source) : false;

  if (validSource) {
    return source;
  }

  if (nivel === 'COMPLETO' && turma === 'pagantes') {
    return 'pagantes';
  } else if (code && nivel === 'COMPLETO') {
    return 'gratuidade-regimental';
  } else {
    return 'acesso-livre';
  }
}

function* formattedEcommerceResult(result, code, sourceParam, user) {
  const { nivelAcesso, turma } = result;
  const source = yield getSource(code, nivelAcesso, turma, sourceParam);

  const valuesToSubmit = {
    ...user,
    subscription: true,
    access: getAccess(nivelAcesso, source),
    codeRegistration: !code && nivelAcesso === 'COMPLETO' ? code : '',
    source
  };

  return valuesToSubmit;
}

function* verifyEcommerceResult(cpf, code) {
  try {
    const resultEcommerce = !code
      ? yield call(getAccessUserByEcommerceWithoutCode, cpf)
      : yield call(getAccessUserByEcommerce, cpf, code);

    if (resultEcommerce.type !== 'Success') {
      yield put(
        createFlashMessage({ type: 'danger', message: resultEcommerce.message })
      );
    }

    return resultEcommerce;
  } catch (error) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
    return undefined;
  }
}

function* submitSignInLocal({ payload }) {
  try {
    const userStatus = yield call(
      checkUserInactiveStatusByEmail,
      payload.email
    );

    if (userStatus === 'blocked') {
      const isSignedIn = yield select(isSignedInSelector);

      if (isSignedIn) {
        yield put(
          createFlashMessage({
            type: 'warning',
            message:
              'Usuário bloqueado por inatividade. Redirecionando para desbloqueio.'
          })
        );

        yield put(hardGoTo(`${apiLocation}/signout`));
      }

      yield put(openModalGoToUnlockPage());
      return;
    }

    const { data } = yield call(postSignInLocal, payload);

    if (data.success) {
      delay(4000);
      yield put(hardGoTo('/learn'));
      yield put(createFlashMessage({ type: 'success', message: data.message }));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitSubscriptionSaga({ payload }) {
  try {
    const codeLocalStorage = localStorage.getItem('devStart:codigo');
    const sourceLocalStorage = localStorage.getItem('devStart:source');
    const resultEcommerce = yield verifyEcommerceResult(
      payload.cpf,
      codeLocalStorage
    );

    const studentData = yield call(
      formattedEcommerceResult,
      resultEcommerce,
      codeLocalStorage,
      cleanSourceInfo(sourceLocalStorage),
      payload
    );

    const dataComplementResult = yield call(
      putRegisterSubscription,
      studentData
    );
    if (dataComplementResult.data.success) {
      window.dataLayer.push({
        event: 'gtm.formSubmit',
        'gtm.elementClasses': 'form-cadastro'
      });

      localStorage.removeItem('devStart:codigo');

      if (localStorage.getItem('devStart:utm') !== null) {
        yield put(updateAccessUtm(localStorage.getItem('devStart:utm')));
      }

      removeNacionalStorageInfo();

      yield put(
        createFlashMessage({
          type: 'success',
          message: dataComplementResult.data.message
        })
      );
      yield delay(4000);
      yield put(hardGoTo('/learn'));
    } else {
      yield put(
        createFlashMessage({
          type: 'danger',
          message: dataComplementResult.data.message
        })
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitConfirmVerificationEmail({ payload }) {
  try {
    const { data } = yield call(postConfirmVerificationEmail, payload);
    if (data.success) {
      delay(2000);
      yield put(hardGoTo('/learn'));
      yield put(createFlashMessage({ type: 'success', message: data.message }));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* updateAccessUtm({ payload }) {
  try {
    const { data } = yield call(putUtmData, JSON.parse(payload));
    if (data.success) {
      localStorage.removeItem('devStart:utm');
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    console.log('Erro Authtentication: ', e);
  }
}

function* submitForgotPassword({ payload }) {
  try {
    const { data } = yield call(postForgotPassword, payload);
    if (data.success) {
      yield put(hardGoTo('/sent-email-to-recovery'));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'danger', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitRecoveryPassword({ payload }) {
  try {
    const { data } = yield call(putRecoveryPassword, payload);
    if (data.success) {
      yield put(createFlashMessage({ type: 'success', message: data.message }));
      yield put(hardGoTo('/learn'));
    } else if (data.error) {
      yield put(createFlashMessage({ type: 'error', message: data.message }));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitCheckUserInactivityByEmail({ payload }) {
  try {
    const userStatus = yield call(checkUserInactiveStatusByEmail, payload);

    if (userStatus === 'blocked') {
      yield put(
        createFlashMessage({
          type: 'error',
          message: 'Usuário bloqueado por inatividade'
        })
      );
      yield put(hardGoTo('/unlock-account'));
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

function* submitUnlockUserAccount({ payload }) {
  try {
    const isGoogleUser = payload.google;

    const {
      data: { result }
    } = yield call(
      isGoogleUser ? unlockGoogleUserAccount : unlockUserAccount,
      payload
    );

    if (result.success) {
      yield put(
        createFlashMessage({
          type: 'success',
          message: result.result.message
        })
      );
      yield put(hardGoTo('/'));
    } else {
      yield put(
        createFlashMessage({ type: 'error', message: result.result.message })
      );
    }
  } catch (e) {
    yield put(
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.UnavailableService
      })
    );
  }
}

export function createAuthenticationSaga(types) {
  return [
    takeLatest(types.signInLocal, submitSignInLocal),
    takeLatest(types.signUpLocal, submitSignUpLocal),
    takeLatest(types.submitSubscription, submitSubscriptionSaga),
    takeLatest(types.verificationEmail, submitConfirmVerificationEmail),
    takeLatest(types.updateAccessUtm, updateAccessUtm),
    takeLatest(types.forgotPassword, submitForgotPassword),
    takeLatest(types.recoveryPassword, submitRecoveryPassword),
    takeLatest(
      types.checkUserInactivityByEmail,
      submitCheckUserInactivityByEmail
    ),
    takeLatest(types.unlockUserAccount, submitUnlockUserAccount)
  ];
}
