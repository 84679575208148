/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Panel } from '@devstart/react-bootstrap';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import { Link } from '../../helpers';
import { Themes } from '../../settings/theme';
import {
  apiLocation,
  courseSesiSenaiUrl,
  discordLocation,
  whatsappSupportNumber
} from '../../../../../config/env.json';

import {
  hardGoTo as navigate,
  openSignoutModal,
  changeTourStatus
} from '../../../redux/actions';
import { updateMyTheme } from '../../../redux/settings/actions';
import { userSelector } from '../../../redux/selectors';
import { User } from '../../../redux/prop-types';

import Profile from '../../../assets/icons/profile';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';
import ChevronUp2 from '../../../assets/icons/chevron-up-2';
import WhatsApp from '../../../assets/icons/whatsapp';
import LockOpen from '../../../assets/icons/lock-open';
import House from '../../../assets/icons/house';
import AccountCircle from '../../../assets/icons/account-circle';
import Help2 from '../../../assets/icons/help-2';
import Discord from '../../../assets/icons/discord';
import Power from '../../../assets/icons/power';
import { AccessLevel } from '../../../utils/enums/access-levels';

import './nav-links.css';
// import Messages from '../../../assets/icons/messages';

export interface NavLinksProps {
  fetchState?: { pending: boolean };
  user: User;
  onHide: () => void;
}

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  navigate,
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  openSignoutModal,
  changeTourStatus
};

function NavLinks({ fetchState, onHide, user }: NavLinksProps) {
  const [toggle, setToggle] = useState(false);

  const { t } = useTranslation();

  const pending = fetchState?.pending;
  const ADMIN_USER = 'admin';

  const linkMap = [
    {
      path: '/learn',
      title: t('buttons.start'),
      icon: <House />,
      adminOnly: false
    },
    {
      path: '/settings',
      title: t('buttons.profile'),
      icon: <AccountCircle />,
      adminOnly: false
    },
    {
      path: '/admin-users',
      title: t('buttons.admin-users'),
      icon: <Profile />,
      adminOnly: true
    }
    // {
    //   path: '/admin/messages/message-dashboard',
    //   title: t('buttons.messages'),
    //   icon: <Messages fill='#727272' />,
    //   adminOnly: true
    // }
  ];

  const number = String(whatsappSupportNumber);
  const infoText = 'Encontrei um erro na plataforma do DEVstart';
  const whatsappURL = `https://api.whatsapp.com/send/?phone=${number}&text=${infoText}&type=phone_number&app_absent=0`;

  return pending ? (
    <div className='nav-skeleton' />
  ) : (
    <div className='nav-list'>
      <div className='logged-user'>
        <span className='user-name'>{user.name}</span>
        <span className='user-email'>{user.email}</span>
      </div>

      {user.access !== AccessLevel.COMPLETE &&
        user.access !== AccessLevel.LimitedNacional &&
        user.access !== AccessLevel.CompleteNacional && (
          <Link
            className='nav-item unlock-content'
            key='learn'
            to={courseSesiSenaiUrl}
            onClick={onHide}
          >
            <LockOpen />
            <span className='nav-link'>{t('buttons.unlock-content')}</span>
          </Link>
        )}

      <div className='items-section'>
        {linkMap.map(({ path, title, icon, adminOnly }) => {
          if (adminOnly && user.type !== ADMIN_USER) {
            return <></>;
          }

          return (
            <Link
              key={path + title}
              className='nav-item'
              to={path}
              onClick={onHide}
            >
              {icon}
              <span className='nav-link'>{title}</span>
            </Link>
          );
        })}

        <Panel eventKey='1' bsClass='custom-accordion-panel' expanded={toggle}>
          <Panel.Title
            toggle
            key={'/help'}
            className='nav-item'
            onClick={() => setToggle(!toggle)}
          >
            <Help2 />
            <span className='nav-link'>{t('buttons.help')}</span>
            {toggle ? (
              <ChevronUp2 className='accordion-chevron' />
            ) : (
              <ChevronDown2 className='accordion-chevron' />
            )}
          </Panel.Title>

          <Panel.Body collapsible>
            <Link
              className='nav-item discord'
              to={String(discordLocation)}
              target='_blank'
              onClick={() => {
                window.dataLayer.push({
                  event: 'gtm.click',
                  'gtm.elementClasses': 'help-link-discord'
                });
              }}
            >
              <Discord />
              <span className='nav-link'>{t('buttons.i-have-a-question')}</span>
            </Link>

            <Link
              className='nav-item whatsApp'
              to={whatsappURL}
              target='_blank'
              onClick={() => {
                window.dataLayer.push({
                  event: 'gtm.click',
                  'gtm.elementClasses': 'help-link-whatsApp'
                });
              }}
            >
              <WhatsApp />
              <span className='nav-link'>{t('buttons.report-an-error')}</span>
            </Link>
          </Panel.Body>
        </Panel>
      </div>

      <a
        key='sign-out'
        className='nav-item'
        href={`${apiLocation}/signout`}
        onClick={onHide}
      >
        <Power />
        <span className='nav-link'>{t('buttons.sign-out')}</span>
      </a>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NavLinks));
